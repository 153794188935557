<template>
  <header id="header">
    <a href="/" class="logo"
      ><img src="img/Simaza Logo-01jpg.jpg" width="80" height="" alt="logo"
    /></a>
    <div class="phone"><i class="fa fa-phone"></i>+250 788 684 665</div>
    <div class="mobile-menu-btn"><i class="fa fa-bars"></i></div>
    <nav class="main-menu top-menu">
      <ul>
        <li class="active"><a href="/">Home</a></li>
        <li><a href="/about">About Us</a></li>
        <li><a href="/apartments">Apartments</a></li>
        <li class=""><a href="/cars">Car Rent</a></li>
        <li><a href="amenities">Amenities</a></li>
        <li><a href="/booking">Booking</a></li>
        <li><a href="https://simazagroup.rw/">Fashions</a></li>
        <!-- <li><a href="login.html">Login</a></li> -->
        <li><a href="/contact">Contact Us</a></li>
      </ul>
    </nav>
  </header>
  <!-- Header Section End -->

  <!-- Header Bottom Start -->
  <div id="header-bottom">
    <!-- Search Section Start -->
    <div id="search" class="search-slider">
      <div class="container">
        <h1>Feel at Home When You're Away</h1>
        <div class="form-row">
          <div class="control-group col-md-3">
            <label>Check-In</label>
            <div class="form-group">
              <div
                class="input-group date"
                id="date-3"
                data-target-input="nearest"
              >
                <input
                  type="date"
                  class="form-control datetimepicker-input"
                  data-target="#date-3"
                />
                <div
                  class="input-group-append"
                  data-target="#date-3"
                  data-toggle="datetimepicker"
                >
                  <div class="input-group-text">
                    <i class="fa fa-calendar"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="control-group col-md-3">
            <label>Check-Out</label>
            <div class="form-group">
              <div
                class="input-group date"
                id="date-4"
                data-target-input="nearest"
              >
                <input
                  type="date"
                  class="form-control datetimepicker-input"
                  data-target="#date-4"
                />
                <div
                  class="input-group-append"
                  data-target="#date-4"
                  data-toggle="datetimepicker"
                >
                  <div class="input-group-text">
                    <i class="fa fa-calendar"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="control-group col-md-3">
            <div class="form-row">
              <div class="control-group col-md-6">
                <label>Adult</label>
                <select class="custom-select">
                  <option selected>0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
              </div>
              <div class="control-group col-md-6 control-group-kid">
                <label>Kid</label>
                <select class="custom-select">
                  <option selected>0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
              </div>
            </div>
          </div>
          <div class="control-group col-md-3">
            <a href="/apartments" class="btn btn-block">Search</a>
          </div>
        </div>
      </div>
    </div>
    <!-- Search Section End -->

    <!-- Slider Section Start -->
    <div id="headerSlider" class="carousel slide" data-ride="carousel">
      <ol class="carousel-indicators">
        <li data-target="#headerSlider" data-slide-to="0" class="active"></li>
        <li data-target="#headerSlider" data-slide-to="1"></li>
        <li data-target="#headerSlider" data-slide-to="2"></li>
      </ol>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="img/hero/_JBP6544.jpg" alt="Royal Hotel" />
        </div>

        <div class="carousel-item">
          <img src="img/hero/_JBP6553.jpg" alt="Royal Hotel" />
        </div>

        <div class="carousel-item">
          <img src="img/hero/_JBP6541.jpg" alt="Royal Hotel" />
        </div>
      </div>

      <a
        class="carousel-control-prev"
        href="#headerSlider"
        role="button"
        data-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a
        class="carousel-control-next"
        href="#headerSlider"
        role="button"
        data-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
    <!-- Slider Section End -->
  </div>
  <!-- Header Bottom End -->

  <!-- Search Section Start -->
  <div id="search" class="search-home">
    <div class="container">
      <div class="form-row">
        <div class="control-group col-md-3">
          <label>Check-In</label>
          <div class="form-group">
            <div
              class="input-group date"
              id="date-5"
              data-target-input="nearest"
            >
              <input
                type="text"
                class="form-control datetimepicker-input"
                data-target="#date-5"
              />
              <div
                class="input-group-append"
                data-target="#date-5"
                data-toggle="datetimepicker"
              >
                <div class="input-group-text">
                  <i class="fa fa-calendar"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="control-group col-md-3">
          <label>Check-Out</label>
          <div class="form-group">
            <div
              class="input-group date"
              id="date-6"
              data-target-input="nearest"
            >
              <input
                type="text"
                class="form-control datetimepicker-input"
                data-target="#date-6"
              />
              <div
                class="input-group-append"
                data-target="#date-6"
                data-toggle="datetimepicker"
              >
                <div class="input-group-text">
                  <i class="fa fa-calendar"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="control-group col-md-3">
          <div class="form-row">
            <div class="control-group col-md-6">
              <label>Adult</label>
              <select class="custom-select">
                <option selected>0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
              </select>
            </div>
            <div class="control-group col-md-6 control-group-kid">
              <label>Kid</label>
              <select class="custom-select">
                <option selected>0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
              </select>
            </div>
          </div>
        </div>
        <div class="control-group col-md-3">
          <button class="btn btn-block">Search</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Search Section End -->

  <!-- Welcome Section Start -->
  <div id="welcome">
    <div class="container">
      <h3>Welcome to Simaza Group</h3>
      <h4>Urban Holiday Apartments in Kicukiro Kigali Rwanda</h4>
      <p>
        Welcome to Simaza Group, your one-stop shop for effortless travel
        planning! Whether you're seeking a comfortable haven for your stay or a
        reliable set of wheels to explore, Simaza Group simplifies your journey.
      </p>
      <a href="/booking">Book Now</a>
    </div>
  </div>
  <!-- Welcome Section End -->

  <!-- Amenities Section Start -->
  <div id="amenities">
    <div class="container">
      <div class="section-header">
        <h2>Amenities & Services</h2>
        <p>
          Simaza Group is your complete travel solution, offering seamless room
          and car reservations in one place
        </p>
      </div>
      <div class="row">
        <div class="col-md-3 col-sm-6">
          <div class="item">
            <i class="icon icon-2"></i>
            <h3>Air Conditioner</h3>
            <p>
              we have air conditioners to keep your home cool and comfortable
              year-round.
            </p>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="item">
            <i class="icon icon-3"></i>
            <h3>Bathtub</h3>
            <p>
              Indulge in a luxurious soak in the comfort of your own en-suite
              bathtub.
            </p>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="item">
            <i class="icon icon-4"></i>
            <h3>Shower</h3>
            <p>
              All rooms feature refreshing en-suite showers for your
              convenience..
            </p>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="item">
            <i class="icon icon-6"></i>
            <h3>Television</h3>
            <p>Relax and unwind in your room with our flat-screen TVs..</p>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="item">
            <i class="icon icon-7"></i>
            <h3>WiFi</h3>
            <p>
              Stay connected throughout your stay with complimentary Wi-Fi
              access in all rooms..
            </p>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="item">
            <i class="icon icon-8"></i>
            <h3>Telephone</h3>
            <p>
              Stay connected with in-room telephones for convenient
              communication. pen_spark .
            </p>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="item">
            <i class="icon icon-9"></i>
            <h3>Mini Bar</h3>
            <p>
              Unwind in your room with our convenient mini-bar stocked with
              refreshing drinks and snacks..
            </p>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="item">
            <i class="icon icon-10"></i>
            <h3>Kitchen</h3>
            <p>
              We Prepare delicious meals at your convenience with our
              fully-equipped in-room kitchens.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Amenities Section Start -->

  <!-- Room Section Start -->
  <div id="rooms">
    <div class="container">
      <div class="section-header">
        <h2>Simaza Villa House & Suites</h2>
        <p>
          Simaza Group offers a collection of stunning villa houses, perfect for
          those seeking a luxurious and private escape.
        </p>
      </div>
      <div class="row">
        <div class="col-md-12 room">
          <div class="row">
            <div class="col-md-6">
              <div class="room-img">
                <img src="img/room-simaza/_JBP6578.jpg" width="600" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="room-des">
                <h3>House A</h3>
                <h1>$800<span>/ Monthly</span></h1>
                <h1>$30<span>/ Day</span></h1>
                <ul class="room-size">
                  <li><i class="fa fa-arrow-right"></i>Size: 260 sq ft</li>
                  <li><i class="fa fa-arrow-right"></i>Beds: 3 Single(s)</li>
                </ul>
                <ul class="room-icon">
                  <li class="icon-1"></li>
                  <li class="icon-2"></li>
                  <li class="icon-3"></li>
                  <li class="icon-4"></li>
                  <li class="icon-5"></li>
                  <li class="icon-6"></li>
                  <li class="icon-7"></li>
                  <li class="icon-8"></li>
                  <li class="icon-9"></li>
                  <li class="icon-10"></li>
                </ul>
                <div class="room-link">
                  <a href="#" data-toggle="modal" data-target="#modal-id"
                    >Read More</a
                  >
                  <a href="/booking">Book Now</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 room">
          <div class="row">
            <div class="col-md-6 d-block d-md-none">
              <div class="room-img">
                <img src="img/room-simaza/_JBP6603.jpg" width="600" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="room-des">
                <h3>House B</h3>
                <h1>$1500<span>/ Monthly</span></h1>
                <h1>$50<span>/ Day</span></h1>
                <ul class="room-size">
                  <li><i class="fa fa-arrow-right"></i>Size: 260 sq ft</li>
                  <li><i class="fa fa-arrow-right"></i>Beds: 3 Single(s)</li>
                </ul>
                <ul class="room-icon">
                  <li class="icon-1"></li>
                  <li class="icon-2"></li>
                  <li class="icon-3"></li>
                  <li class="icon-4"></li>
                  <li class="icon-5"></li>
                  <li class="icon-6"></li>
                  <li class="icon-7"></li>
                  <li class="icon-8"></li>
                  <li class="icon-9"></li>
                  <li class="icon-10"></li>
                </ul>
                <div class="room-link">
                  <a href="#" data-toggle="modal" data-target="#modal-id-2"
                    >Read More</a
                  >
                  <a href="/booking">Book Now</a>
                </div>
              </div>
            </div>
            <div class="col-md-6 d-none d-md-block">
              <div class="room-img">
                <img src="img/room-simaza/_JBP6603.jpg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Room Section End -->

  <!-- Modal for House A Section Start -->
  <div id="modal-id" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="port-slider">
                <div><img src="img/room-simaza/_JBP6602.jpg" /></div>
                <div><img src="img/room-simaza/_JBP6603.jpg" /></div>
                <div><img src="img/room-simaza/_JBP6607.jpg" /></div>
                <div><img src="img/room-simaza/_JBP6626.jpg" /></div>
                <div><img src="img/room-simaza/_JBP6633.jpg" /></div>
                <div><img src="img/room-simaza/_JBP6637.jpg" /></div>
              </div>
              <div class="port-slider-nav">
                <div><img src="img/room-simaza/_JBP6554.jpg" /></div>
                <div><img src="img/room-simaza/_JBP6837.jpg" /></div>
                <div><img src="img/room-simaza/_JBP6832.jpg" /></div>
              </div>
            </div>
            <div class="col-12">
              <h2>House A Description</h2>
              <p>
                Don't hesitate to reach out directly to the property with any
                questions about the room after seeing the images, also you can
                mention specific room features or potential discrepancies
                between photos and reality.
              </p>
              <div class="modal-link">
                <a href="/booking">Book Now</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal for House A Section End -->

  <!-- Modal for House B Section Start -->
  <div id="modal-id-2" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="port-slider">
                <div><img src="img/room-slider/room-1.jpg" /></div>
                <div><img src="img/room-slider/room-2.jpg" /></div>
                <div><img src="img/room-slider/room-3.jpg" /></div>
                <div><img src="img/room-slider/room-4.jpg" /></div>
                <div><img src="img/room-slider/room-5.jpg" /></div>
                <div><img src="img/room-slider/room-6.jpg" /></div>
              </div>
              <div class="port-slider-nav">
                <div><img src="img/room-slider/room-1.jpg" /></div>
                <div><img src="img/room-slider/room-2.jpg" /></div>
                <div><img src="img/room-slider/room-3.jpg" /></div>
                <div><img src="img/room-slider/room-4.jpg" /></div>
                <div><img src="img/room-slider/room-5.jpg" /></div>
                <div><img src="img/room-slider/room-6.jpg" /></div>
              </div>
            </div>
            <div class="col-12">
              <h2>House B Description</h2>
              <p>
                Don't hesitate to reach out directly to the property with any
                questions about the room after seeing the images, also you can
                mention specific room features or potential discrepancies
                between photos and reality.
              </p>
              <div class="modal-link">
                <a href="/booking">Book Now</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal for House B Section End -->

  <!-- Subscribe Section Start -->
  <div id="subscribe">
    <div class="container">
      <div class="section-header">
        <h2>Subscribe for Special Offer</h2>
        <p>
          Be the first to know about upcoming promotions, explore our latest
          listings, and plan your perfect getaway with just a few clicks.
        </p>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="subscribe-form">
            <form>
              <input
                type="email"
                required="required"
                placeholder="Enter your email here"
              />
              <button>submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Subscribe Section End -->

  <!-- Booking Section Start -->
  <div id="booking">
    <div class="container">
      <div class="section-header">
        <h2>Room Booking</h2>
        <p>
          Book your perfect stay with us! Choose from a variety of comfortable
          rooms, from spacious productivity havens with dedicated workspaces to
          cozy retreats ideal for relaxation. pen_spark
        </p>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="booking-form">
            <div id="success"></div>
            <form name="sentMessage" id="bookingForm" novalidate="novalidate">
              <div class="form-row">
                <div class="control-group col-sm-6">
                  <label>First Name</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="firstName"
                    placeholder="E.g. John"
                    required="required"
                    data-validation-required-message="Please enter first name"
                  />
                  <p class="help-block text-danger"></p>
                </div>
                <div class="control-group col-sm-6">
                  <label>Last Name</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="lastName"
                    placeholder="E.g. Sina"
                    required="required"
                    data-validation-required-message="Please enter last name"
                  />
                  <p class="help-block text-danger"></p>
                </div>
              </div>
              <div class="form-row">
                <div class="control-group col-sm-6">
                  <label>Mobile</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="phoneNumber"
                    placeholder="E.g. +1 234 567 8900"
                    required="required"
                    data-validation-required-message="Please enter your mobile number"
                  />
                  <p class="help-block text-danger"></p>
                </div>
                <div class="control-group col-sm-6">
                  <label>Email</label>
                  <input
                    type="email"
                    class="form-control"
                    v-model="email"
                    placeholder="E.g. email@example.com"
                    required="required"
                    data-validation-required-message="Please enter your email"
                  />
                  <p class="help-block text-danger"></p>
                </div>
              </div>
              <div class="form-row">
                <div class="control-group col-sm-6">
                  <label>Check-In</label>
                  <input
                    type="date"
                    class="form-control datetimepicker-input"
                    v-model="checkIn"
                    data-toggle="datetimepicker"
                    data-target="#date-1"
                    placeholder="E.g. MM/DD/YYYY"
                    required="required"
                    data-validation-required-message="Please enter date"
                  />
                  <p class="help-block text-danger"></p>
                </div>
                <div class="control-group col-sm-6">
                  <label>Check-Out</label>
                  <input
                    type="date"
                    v-model="checkOut"
                    class="form-control datetimepicker-input"
                    data-toggle="datetimepicker"
                    data-target="#date-2"
                    placeholder="E.g. MM/DD/YYYY"
                    required="required"
                    data-validation-required-message="Please enter date"
                  />
                  <p class="help-block text-danger"></p>
                </div>
              </div>
              <div class="form-row">
                <div class="control-group col-sm-6">
                  <label>Adult</label>
                  <select
                    class="custom-select"
                    v-model="adult"
                  >
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>
                  <p class="help-block text-danger"></p>
                </div>
                <div class="control-group col-sm-6">
                  <label>KId</label>
                  <select
                    class="custom-select"
                    v-model="kid"
                  >
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>
                  <p class="help-block text-danger"></p>
                </div>
              </div>
              <div class="control-group">
                <label>Special Request</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="specialRequest"
                  placeholder="E.g. Special Request"
                  required="required"
                  data-validation-required-message="Please enter your special request"
                />
                <p class="help-block text-danger"></p>
              </div>
              <div class="button">
                <button type="button" id="bookingButton" @click="bookNow()">
                  Book Now
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Booking Section End -->

  <!-- Call Section Start -->
  <div id="call-us">
    <div class="container">
      <div class="section-header">
        <h2>Click Below to Call Us</h2>
        <p>
          Skip the typing and connect instantly! Click the button below to be
          directly connected to our friendly customer service team. They're
          happy to answer your questions and assist you – all with a single
          click.
        </p>
      </div>
      <div class="row">
        <div class="col-12">
          <a href="tel:+250789326245">+250 788 684 665</a>
        </div>
      </div>
    </div>
  </div>
  <!-- Call Section End -->

  <!-- Footer Section Start -->
  <div id="footer">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="social">
            <a href="">
              <li class="fa fa-instagram"></li>
            </a>
            <a href="">
              <li class="fa fa-twitter"></li>
            </a>
            <a href="">
              <li class="fa fa-facebook-f"></li>
            </a>
          </div>
        </div>
        <div class="col-12">
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/about">About</a></li>
            <li><a href="">Terms</a></li>
            <li><a href="/contact">Contact</a></li>
          </ul>
        </div>
        <div class="col-12">
          <p>
            Copyright &#169;
            <a href="#">Simaza Group Rent property</a> All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- Footer Section End -->

  <a href="#" class="back-to-top"><i class="fa fa-chevron-up"></i></a>
</template>
<script>
export default {
  data () {
    return {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      checkIn: '',
      checkOut: '',
      adult: '1',
      kid: '1',
      specialRequest: ''
    }
  },
  methods: {
    bookNow () {
      const title = 'House for rent from ' + 'https://simaza-group-rent-property.pages.dev/  \n '
      const clientNames = this.firstName + '-' + this.lastName + '\n'
      const clientEmail = 'Client Email: ' + this.email + '\n'
      const checkInCheckout = 'check in ' + this.checkIn + '   ' + 'and check out : ' + this.checkOut + '\n '
      const adult = 'Adult: ' + this.adult + '\n'
      const kids = 'Kids: ' + this.kid + '\n'
      const specialRequest = 'Special Request: ' + this.specialRequest + ' \n '
      const phoneNumber = '250789326245'
      const message = `${title} Client Names : ${clientNames}  ${clientEmail} with ${checkInCheckout} ${adult} ${kids} ${specialRequest}`
      const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`
      window.open(whatsappUrl, '_blank')
    }
  }
}
</script>
